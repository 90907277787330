import { PageData } from '@wix/platform-editor-sdk';
import { TpaPageId } from '@wix/pricing-plans-router-utils';
import type { ControllerParams } from '@wix/yoshi-flow-editor';
import { PRICING_PLANS_APP_DEF_ID } from '../constants';

type WixCodeApi = ControllerParams['flowAPI']['controllerConfig']['wixCodeApi'];
type AppParams = ControllerParams['flowAPI']['controllerConfig']['appParams'];

export interface PageInstallationInfo {
  tpaPageId: TpaPageId;
  pageData?: Partial<PageData>;
}

export const MPA_PAGES: PageInstallationInfo[] = [
  {
    tpaPageId: TpaPageId.PackagePicker,
    pageData: { indexable: true, hidePage: false },
  },
  {
    tpaPageId: TpaPageId.Checkout,
    pageData: { hidePage: true },
  },
  {
    tpaPageId: TpaPageId.ThankYou,
    pageData: { hidePage: true },
  },
  {
    tpaPageId: TpaPageId.Paywall,
    pageData: { indexable: false, hidePage: true },
  },
];

export const MPA_ON_ECOM_PAGES: PageInstallationInfo[] = [
  {
    tpaPageId: TpaPageId.PackagePicker,
    pageData: { indexable: true, hidePage: false },
  },
  {
    tpaPageId: TpaPageId.PlanCustomization,
    pageData: { indexable: true, hidePage: true },
  },
  {
    tpaPageId: TpaPageId.ThankYou,
    pageData: { title: 'Continue', hidePage: true },
  },
  {
    tpaPageId: TpaPageId.Paywall,
    pageData: { indexable: false, hidePage: true },
  },
];

export async function isMultiPageApp(wixCodeApi: WixCodeApi, appParams: AppParams): Promise<boolean> {
  if (appParams.appRouters?.length !== 1) {
    return false;
  }
  return (await Promise.all([isMpa(wixCodeApi), isMpaOnEcom(wixCodeApi)])).some((result) => result);
}

async function isMpa(wixCodeApi: WixCodeApi): Promise<boolean> {
  return (
    await Promise.all(
      MPA_PAGES.map((page) =>
        wixCodeApi.site.isAppSectionInstalled({
          sectionId: page.tpaPageId,
          appDefinitionId: PRICING_PLANS_APP_DEF_ID,
        }),
      ),
    )
  ).every((section) => section);
}

export async function isMpaOnEcom(wixCodeApi: WixCodeApi): Promise<boolean> {
  return (
    await Promise.all(
      MPA_ON_ECOM_PAGES.map((page) =>
        wixCodeApi.site.isAppSectionInstalled({
          sectionId: page.tpaPageId,
          appDefinitionId: PRICING_PLANS_APP_DEF_ID,
        }),
      ),
    )
  ).every((section) => section);
}
